<template>
  <Layout>
    <div class="container">
      <h1 class="is-clearfix">
        <span class="is-pulled-left">Custom Actions</span>
        <button
          id="add"
          @click="active = 'is-active'"
          class="button is-info is-pulled-right"
        >
          Add a new Custom Action
        </button>
      </h1>
      <section class="main-table-container">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>URL</td>
              <td>Company</td>
              <td v-if="isAdmin">Actions</td>
            </tr>
          </thead>
          <!-- END of thead -->
          <tbody>
            <tr
              v-for="customAction in customActions"
              v-bind:key="customAction._id"
            >
              <td>{{ customAction.name }}</td>
              <td>{{ customAction.description }}</td>
              <td>{{ customAction.URL }}</td>
              <td>{{ customAction.company ? customAction.company.name : 'N/A' }}</td>
              <td v-if="isAdmin" class="actions">
                <div class="field has-addons">
                  <p class="control">
                    <a
                      class="button tooltip is-tooltip-bottom"
                      data-tooltip="Edit Action"
                      @click="promptUpdateAction(customAction)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'pencil-alt']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      class="button tooltip is-danger is-tooltip-bottom"
                      data-tooltip="Delete Action"
                      @click="confirmActionRemoval(customAction)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- END of tbody -->
        </table>
      </section>
      <div class="modal modalContainer" :class="active">
        <div class="modal-background"></div>
        <div class="modal-card modalBody">
          <header class="modal-card-head modalHeader">
            <p class="modal-card-title">
              <span v-if="!modalCustomAction._id">Add a New CustomAction</span>
              <span v-else>Update {{ modalCustomAction.name }} Action</span>
            </p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Name</label>
                  <div class="control">
                    <input
                      v-model="modalCustomAction.name"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Description</label>
                  <div class="control">
                    <input
                      v-model="modalCustomAction.description"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">URL</label>
                  <div class="control">
                    <input
                      v-model="modalCustomAction.URL"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label" for="company">Company</label>
                  <div class="control">
                    <div class="select">
                      <select
                        v-model="company"
                        ref="company"
                        name="company"
                        class="input"
                        :class="{ 'is-danger': error.company }"
                        required="required"
                      >
                        <option
                          v-for="(company, index) in companies"
                          :key="index"
                          :value="company"
                        >
                          {{ company.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class="help" :class="{ 'is-danger': error.company }">
                    {{ error.company }}
                  </p>
                </div>
              </div>
            </div>
            <p v-if="error.error" class="help is-danger">{{ error.message }}</p>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="cancelModal()">
              Cancel
            </button>
            <button
              class="button is-success"
              @click="
                addCustomAction({
                  update: modalCustomAction._id ? true : false
                })
              "
            >
              <span v-if="!modalCustomAction._id">Add</span>
              <span v-else>Update</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
    <modal
      v-show="showModalConfirmation"
      @closeModal="closeModal"
      v-bind="modalConfig"
    />
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'customActions',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  data() {
    return {
      customActions: [],
      active: '',
      modalCustomAction: {
        name: '',
        description: '',
        URL: '',
        company: '',
      },
      error: {
        error: false,
        message: 'Something went wrong'
      },
      actionToDelete: null,
      showModalConfirmation: false,
      modalConfig: {
        bodyText: 'Are you sure you want to delete this action?',
        titleText: 'Delete Confirmation',
        buttons: {
          confirm: {
            text: 'Delete',
            class: 'is-danger'
          },
          reject: {
            text: 'Cancel',
            class: ''
          }
        }
      },
      companies: [],
      company: {},
    };
  },
  async beforeMount() {
    // Fetch user info.
    let userCompanyId = $cookies.get($formsConfig.core.cookieName).company;
    // Fetch the list of the companies.
    const response = await axios.get($formsConfig.core.api.companies);
    this.companies = response.data || {};
    this.company = this.companies.find(company => {
      return company._id === userCompanyId;
    });
  },
  computed: {
    isAdmin() {
      return $cookies.get($formsConfig.core.cookieName).role === 'admin';
    }
  },
  methods: {
    //Add a new custom action
    async addCustomAction({ update }) {
      // set company id according to selected company.
      this.modalCustomAction.company = this.company._id;
      if (update) {
        this.updateAction();
        return;
      }
      try {
        const response = await axios.post(
          $formsConfig.core.api.customActions,
          this.modalCustomAction
        );
        this.error.error = false;
        this.customActions.push({
            ...response.data,
            company: {
              company: this.company._id,
              name: this.company.name,
            }
        });
        this.active = '';
        this.modalCustomAction.name = '';
        this.modalCustomAction.description = '';
        this.modalCustomAction.URL = '';
      } catch (error) {
        this.error.error = true;
        this.error.message = e.response.data.message;
      }
    },
    promptUpdateAction(action) {
      this.modalCustomAction._id = action._id;
      this.modalCustomAction.name = action.name;
      this.modalCustomAction.description = action.description;
      this.modalCustomAction.URL = action.URL;
      this.active = 'is-active';
    },
    async updateAction() {
      try {
        await axios.put(
          $formsConfig.core.api.customActions + this.modalCustomAction._id,
          this.modalCustomAction
        );
        this.error.error = false;
        this.active = '';
        this.customActions.forEach(action => {
          if (action._id === this.modalCustomAction._id) {
            action.name = this.modalCustomAction.name;
            action.description = this.modalCustomAction.description;
            action.URL = this.modalCustomAction.URL;
            action.company = {
              company: this.company._id,
              name: this.company.name,
            }
          }
        });
        this.modalCustomAction._id = null;
        this.modalCustomAction.name = '';
        this.modalCustomAction.description = '';
        this.modalCustomAction.URL = '';
      } catch (error) {
        this.error.error = true;
        this.error.message = error.response.data.message;
      }
    },
    confirmActionRemoval(action) {
      this.showModalConfirmation = true;
      this.actionToDelete = action._id;
    },
    async closeModal(result) {
      if (result.remove) {
        await this.deleteAction();
        this.showModalConfirmation = false;
      } else {
        this.showModalConfirmation = false;
      }
    },
    async deleteAction() {
      let index;
      this.customActions.forEach((el, i) => {
        if (el._id === this.actionToDelete) {
          return (index = i);
        }
      });
      try {
        const response = await axios.delete(
          $formsConfig.core.api.customActions + this.actionToDelete
        );
        if (response.status === 200) {
          this.customActions.splice(index, 1);
          this.actionToDelete = null;
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    cancelModal() {
      this.active = '';
      this.error.error = false;
      this.modalCustomAction.name = '';
      this.modalCustomAction.description = '';
      this.modalCustomAction.URL = '';
    }
  },
  created() {
    // Fetch the list of forms for the custom actions.
    axios.get($formsConfig.core.api.customActions).then(response => {
      this.customActions = response.data;
    });
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
</style>
